<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_new_product" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90%" style="direction:ltr;padding:5px;" right title="اضافة منتج" shadow >
                <template #default="{ hide }" >
                <div class="m-1 text-start" style="direction:rtl;margin:5px;">
                    <div style="width:130px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.add_edit_product}}</span>
                    </div>
                    <div @click="hide" id="hidecrmprodaddedit" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl;margin:5px;" class="m-2">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="8">
                                <div class="backBlack text-center">معلومات المنتج</div>
                                <v-row>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{lang.item_code}}</label>
                                        <b-form-input class="inborder"
                                            v-model="product.item_code"
                                            @change="getItem()"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{lang.internal_code}}</label>
                                        <b-form-input class="inborder"
                                            v-model="product.internal_code"
                                            @change="getItem()"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{lang.product_namear}}</label>
                                        <b-form-input class="inborder"
                                            v-model="product.item_namear"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{lang.product_nameen}}</label>
                                        <b-form-input class="inborder"
                                            v-model="product.item_nameen"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.product_category}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.catid" 
                                            :options="categories"
                                            ></b-form-select>
                                            
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.can_be_returned}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.can_be_returned" 
                                            :options="$store.state[`yesNoList_${lang.langname}`]"
                                            ></b-form-select>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.main_unit}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.main_unit" 
                                            :options="mainUnits"
                                            @change="collectPrices()"
                                            ></b-form-select>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.product_type}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.item_type" 
                                            :options="productstype"
                                            ></b-form-select>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.is_finalProduct}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.is_finalProduct" 
                                            :options="isFinal"
                                            ></b-form-select>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box">
                                        <label>{{lang.is_saleable}}</label>
                                        <b-input-group>
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="product.saleable" 
                                            :options="saleAbles"
                                            >
                                            </b-form-select>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12">
                                        <label>{{lang.def_descriptions}}</label>
                                        <b-form-textarea class="inborder"
                                            v-model="product.def_descriptions"
                                        ></b-form-textarea>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12" class="select_box" v-for="(value, key) in extra" :key="key">
                                        <label>{{extraTitles[`${key}`][lang.langname]}}</label>
                                        <b-input-group v-if="extraType[key] == 'select'">
                                            <b-form-select class="selborder" 
                                            style="width:200px !important" 
                                            v-model="extra[key]" 
                                            :options="allExtraSet[key]"
                                            >
                                            </b-form-select>
                                        </b-input-group>
                                        <b-form-input class="inborder" v-if="extraType[key] == 'text'" v-model="extra[key]" />
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12">
                                        <label>{{lang.prices_and_costs}}</label>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-simple-table>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center backBlack">{{lang.unit_name}}</th>
                                                            <th class="text-center backBlack">{{lang.default_price}}</th>
                                                            <th class="text-center backBlack">{{lang.default_discount}}</th>
                                                            <th class="text-center backBlack">{{lang.min_price}}</th>
                                                            <th class="text-center backBlack">{{lang.max_price}}</th>
                                                            <th class="text-center backBlack">{{lang.default_cost}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in prices" :key="index">
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].unit_name" /></td>
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].default_price" /></td>
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].default_discount" /></td>
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].min_price" /></td>
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].max_price" /></td>
                                                            <td class="text-center"><b-form-input class="inborder" v-model="prices[index].default_cost" /></td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4">
                                <div class="backBlack text-center">بيانات الربط</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-row>
                            <v-col cols="4">
                                <div div class="backBlack text-center">الاكواد البديلة</div>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center backBlack">{{lang.item_code}}</th>
                                            <th class="text-center backBlue">{{lang.action}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center backBlack">
                                                <b-form-input class="inborder" v-model="newItemRow" />
                                            </th>
                                            <th class="text-center backBlue" style="cursor:pointer;width:80px;" @click="addNewRow()">
                                                {{ lang.add }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in product.replacement_codes" :key="index">
                                            <td class="text-center">{{ item.item_code }}</td>
                                            <th class="text-center backRed" style="cursor:pointer;width:80px;" @click="deleteRow(index)">
                                                {{ lang.delete }}
                                            </th>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-center backBlack">الاصناف الشبيهة</div>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center backBlack" style="width:150px;">{{lang.item_code}}</th>
                                            <th class="text-center backBlack">{{lang.item_name}}</th>
                                            <th class="text-center backBlue">{{lang.action}}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center backBlack" colspan="2">
                                                <b-form-input class="inborder" v-model="newSimRow" />
                                            </th>
                                            <th class="text-center backBlue" style="cursor:pointer;width:80px;" @click="addSimNewRow()">
                                                {{ lang.add }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in similarItems" :key="index">
                                            <td class="text-center">{{ item.item_code }}</td>
                                            <td class="text-center">{{ item[`item_name${lang.langname}`] }}</td>
                                            <th class="text-center backRed" style="cursor:pointer;width:80px;" @click="deleteSimRow(index)">
                                                {{ lang.delete }}
                                            </th>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addIt()" variant="success" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                    </div>
                </template>
            </b-sidebar>
            <vue-snotify></vue-snotify>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            newItemRow: "",
            newSimRow: "",
            item_id: 0,
            product: {
                item_code: '',
                internal_code: '',
                item_namear: '',
                item_nameen: '',
                item_type: 1,
                catid: 1,
                def_descriptions: '',
                can_be_returned: 1,
                is_finalProduct: 1,
                saleable: 1,
                main_unit: 1,
                replacement_codes: []
            },
            similarItems: [],
            prices: [],
            extra: {},
            productcats: [],
            units: [],
            subUnits: [],
            deftype: 1,
            allExtraSet: {},
            extraTitles: {},
            extraType: {}
        }
    },
    methods: {
        checkItemExist(ar, value, key){
            return ar.some(obj => obj[key] === value); 
        },
        addNewRow(){
            if(this.newItemRow.trim() == '') return false;
            if(!this.checkItemExist(this.product.replacement_codes, this.newItemRow, 'item_code')){
                this.product.replacement_codes.push({id: 0, item_code: this.newItemRow});
            }
            this.newItemRow = '';
        },
        addSimNewRow(){
            if(this.newSimRow.trim() == '') return false;
            if(this.checkItemExist(this.similarItems, this.newSimRow, 'item_code')) return false;

            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.lang.langname);
            post.append('type','getInventoryProduct');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_code]',this.newSimRow.trim())
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                let res = response.data.results.data;
                for(let i=0;i<res.length;i++){
                    this.similarItems.push(res[i])
                }
            })
        },

        addIt(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.lang.langname);
            post.append('type','addNewProductStore');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[product]',JSON.stringify(this.product))
            post.append('data[extra]',JSON.stringify(this.extra))
            post.append('data[similarItems]',JSON.stringify(this.similarItems))
            post.append('data[prices]',JSON.stringify(this.prices))
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response.data.error.number == 200){
                    let message = "تمت الاضافة بنجاح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    const res = response.data;
                    // // console.log("hello",res);
                    this.resetAll();
                    document.getElementById('hidecrmprod').click();
                    this.$parent.getProducts();
                }else{
                    let message = this.lang[response.data.error.message];
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    const res = response.data;
                    // // console.log("hello",res);
                    this.resetAll();
                    document.getElementById('hidecrmprodaddedit').click();
                    this.$parent.getProducts();
                }
            })
        },

        deleteRow(index){
            this.product.replacement_codes.splice(index,1)
        },
        deleteSimRow(index){
            this.similarItems.splice(index,1)
        },
        resetAll (){
            this.newItemRow = ""
            this.newSimRow = ""
            this.item_id = 0
            this.product = {
                item_code: '',
                internal_code: '',
                item_namear: '',
                item_nameen: '',
                item_type: 1,
                catid: 1,
                def_descriptions: '',
                can_be_returned: 1,
                is_finalProduct: 1,
                saleable: 1,
                main_unit: 1,
                replacement_codes: []
            }
            this.similarItems = []
            this.prices = []
            this.extra = {}
            this.productcats = []
            this.units = []
            this.subUnits = []
            this.deftype = 1
            this.allExtraSet = {}
            this.extraTitles = {}
            this.extraType = {}
            this.getCats();
            this.getExtraSettings();
        },
        collectPrices(){
            this.prices = [];
            Object.keys(this.subUnits[this.product.main_unit]).forEach(key => {
                this.prices.push({
                    id: 0,
                    unit_name: this.subUnits[this.product.main_unit][key][`name${this.lang.langname}`],
                    item_id: this.item_id,
                    unit_id: this.subUnits[this.product.main_unit][key].id,
                    default_price: 0,
                    max_price: 0,
                    min_price: 0,
                    default_discount: 0,
                    default_cost: 0,
                })
            })
        },
        getCats(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getPCatsList");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all0");
            post.append('data[lang]',"ar");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push({
                                text: p.results.data[i].namear,
                                value: p.results.data[i].id
                            });
                            // if(i == 0){
                                this.product.catid = p.results.data[i].id
                                this.deftype = p.results.data[i].id
                            // }
                        }
                    }
                }
                this.productcats = t;
                this.getUnits();
            });
        },
        getExtraSettings(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getExtraSettings");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[table]',"inventory_products")
            post.append('lang',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const res_ = res.data.results.data;
                this.extraTitles = res.data.results.titles
                this.extraType = res.data.results.extraType
                Object.keys(res_).forEach(key => {
                    this.extra[key] = "";
                })
                this.allExtraSet = res_;
            });
        },
        getUnits(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.lang.langname);
            post.append('type','getUnits');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[units]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.units = response.data.results.data;
                    this.subUnits = response.data.results.subUnits;
                }
                this.collectPrices();
            })
        },
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        branchesIList: function (){
            let t = []
            for (let i=0 ; i < this.branchesLists.length ; i++){
                t.push(
                    {
                        text: this.branchesLists[i][`name_${this.lang.langname}`],
                        value: this.branchesLists[i].id,
                    }
                )
            }
            return t;
        },
        storesIList: function (){
            let t = []
            for (let i=0 ; i < this.storesList.length ; i++){
                t.push(
                    {
                        text: this.storesList[i][`name_${this.lang.langname}`],
                        value: this.storesList[i].id,
                    }
                )
            }
            return t;
        },
        taxesIList: function (){
            let t = []
            for (let i=0 ; i < this.taxesList.length ; i++){
                t.push(
                    {
                        text: this.taxesList[i][`name${this.lang.langname}`],
                        value: this.taxesList[i].id,
                    }
                )
            }
            return t;
        },
        categories: function(){
            let t = [];
            for(let i=0;i<this.productcats.length;i++){
                
                t.push({
                    text: this.productcats[i].text,
                    value: this.productcats[i].value
                })
            }
            return t;
        },
        isFinal: function(){
            let t = [];
            t.push({
                text: this.lang.final_product,
                value: 1,
            })
            t.push({
                text: this.lang.raw_material,
                value: 2,
            })
            if(this.$store.state.licenseType.manafactoring_enable){
                t.push({
                    text: this.lang.factory_item,
                    value: 3,
                })
            }
            
            return t;
        },
        saleAbles: function(){
            let t = [];
            t.push({
                text: this.lang.salesable,
                value: 1,
            })
            t.push({
                text: this.lang.none_salesable,
                value: 2,
            })
            
            return t;
        },
        productstype: function(){
            return [
                {text: this.lang.inventory_item,value: 1},
                {text: this.lang.service_item,value: 2},
            ]
        },
        mainUnits: function(){
            let t = [];
            for(let i=0;i<this.units.length;i++){
                if(this.units[i].type == 1){
                    t.push({
                        text: this.units[i][`name`+this.lang.langname],
                        value: this.units[i].id
                    })
                }
                
            }
            return t;
        }
    },
    created() {
       this.getCats();
       this.getExtraSettings();
    },
}
</script>
        
<style>
label{
    color:#000 !important;
}
.select_box{
  overflow: hidden;
  position: relative;
}
.select_box:after{
  width: 0; 
  height: 0; 
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  top: 60%;
  left: 20px;
  content: "";
  z-index: 500;
 }
.select_box select{
  width: 220px;
  border: 0;
  position: relative;
  z-index: 99;
}
</style>